<template>
      <e-charts autoresize :options="bar" ref="bar" theme="ovilia-green" auto-resize />
</template>

<script>
import ECharts from 'vue-echarts/components/ECharts'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/legend'
import 'echarts/lib/chart/bar'
import theme from './theme.json'

ECharts.registerTheme('ovilia-green', theme)

export default {
  data () {
    return {
      bar: {
        legend: {},
        tooltip: {},
        dataset: {
          // Provide data.
          source: [
            ['Product', '2015', '2016', '2017', '2018'],
            ['Riv', ...this.randomize()],
            ['Yop', ...this.randomize()],
            ['Bas', ...this.randomize()],
            ['Bke', ...this.randomize()],
            ['Dal', ...this.randomize()]
          ]
        },
        // Declare X axis, which is a category axis, mapping
        // to the first column by default.
        xAxis: { type: 'category' },
        // Declare Y axis, which is a value axis.
        yAxis: {},
        // Declare several series, each of them mapped to a
        // column of the dataset by default.
        series: [{ name: 'Valeur ventes', type: 'bar' }, { type: 'bar' }, { type: 'bar' }, {type: 'bar'}]

      }
    }
  },
  methods: {
    randomize () {
      return [
        Math.round(300 + (Math.random() * 700)) / 10,
        Math.round(300 + (Math.random() * 700)) / 10,
        Math.round(300 + (Math.random() * 700)) / 10,
        Math.round(300 + (Math.random() * 700)) / 10
      ]
    }
  },
  components: {
    ECharts
  }
}
</script>
